import {GoogleApiWrapper} from 'google-maps-react';
import {Map, InfoWindow, Marker} from 'google-maps-react';
import React, {Component} from "react";

export class MapContainer extends Component {
    state = {
      showingInfoWindow: true,
      activeMarker: {},
      selectedPlace: {},

      mapCenter:{},
      latitude:15.73,
      longitude:73.4
    };
    MyCustomMarker = () => <span class="material-icons">place</span>;
    onMarkerClick = (props, marker, e) =>
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
   
    onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        })
      }
    };

    

    componentDidMount(){
      // this.getCurrentLocation(this.props.location.state.bookingid)
      let value =this.props.location.state.latLong
      console.log("in maps",value)
      this.setState({
        latitude:parseFloat(value.lng),
        longitude:parseFloat(value.lat)
       })
    }
    
   
    render() {
      const lat = this.state.latitude;
      const lng = this.state.longitude
        return (
          <Map google={this.props.google}
          yesIWantToUseGoogleMapApiInternals={true}
          zoom={16}
          initialCenter={{
            lat:15,
            lng:15
          }}
          center={{
            lat:lat,
            lng:lng
          }}
          >
            <Marker 
             position={{
              lat:lat,
              lng:lng
          }} 
          />
          </Map>
      
      
      )
    }
  }


  export default GoogleApiWrapper({
    apiKey: ("AIzaSyBAbp0i9Uf5MeGrJHbGGyyB4gkP-kQh-II")
  })(MapContainer)
  